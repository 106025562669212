/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            image
            url
            siteUrl
          }
        }
      }
    `
  )
  
  // let currentPathName = ''

  // if(typeof window !== `undefined`) {
  //   const currentPathName = location.pathname
  // }
  
  // let currentPathName = typeof window !== "undefined" ? window.location.pathname : ""'
  const currentPathName = typeof window !== 'undefined' && window.location.pathname;;
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const siteImage = image || site.siteMetadata?.image
  const siteURL = site.siteMetadata?.url
  const featuredImage = siteURL + "/" + siteImage
  // const canonical = currentPathName ? `${site.siteMetadata.siteUrl}${currentPathName}` : `${site.siteMetadata.siteUrl}`
  const canonical = `${site.siteMetadata.siteUrl}${currentPathName}` 

  if (process.env.GATSBY_ACTIVE_ENV === 'development') {
    console.log("**siteImage**: " + siteImage)
    console.log("**siteURL**: " + siteURL)
    console.log("**featuredImage**: " + featuredImage)
    console.log("**metaDescription**: " + metaDescription)
    console.log("**description**: " + description)
    console.log("**title**: " + title)
    console.log("**currentPathName**: " + currentPathName)
    console.log("**canonical**: " + canonical)
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle} ` : null}
      // **REMOVED*** removed since we are using the separate plugin to specify canonical URL
      link={
        siteURL
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'image',
          content: featuredImage,
        },
        {
          name: `author`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: featuredImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          name: `twitter:site`,
          content: `@stickchart`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `@stickchart`
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: featuredImage,
        },
        {
          itemprop: `name`,
          content: title,
        },
        {
          itemprop: `description`,
          content: metaDescription,
        },
        {
          itemprop: `image`,
          content: featuredImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
